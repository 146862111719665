@mixin transition($duration) {
    -o-transition: $duration;
    -moz-transition: $duration;
    -webkit-transition: $duration;
    transition: $duration;
}

@mixin social_color($icon-name-to-color-name-map, $name) {
    color: map-get($social-colors, map-get($icon-name-to-color-name-map, $name));
}
