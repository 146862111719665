// Needs refactoring
// stylelint-disable selector-max-type
.markdown-body {
  // Tables
  table {
    display: block;
    width: 100%; // keep for backwards compatibility
    width: max-content;
    max-width: 100%;
    overflow: auto;

    th {
      font-weight: $font-weight-bold;
    }

    th,
    td {
      // stylelint-disable-next-line primer/spacing
      padding: 6px 13px;
      // stylelint-disable-next-line primer/borders
      border: $border-width $border-style lighten($gray-300, 5%);
    }

    tr {
      background-color: $bg-white;
      // stylelint-disable-next-line primer/borders
      border-top: $border-width $border-style darken($gray-300, 4%);

      &:nth-child(2n) {
        background-color: $bg-gray;
      }
    }

    img {
      background-color: transparent;
    }
  }
}
