// Optional offset options to work with grid.scss

// Offset Columns

@each $breakpoint, $variant in $responsive-variants {
  @include breakpoint($breakpoint) {
    .offset#{$variant}-1 { margin-left: (1 / 12 * 100%) !important; }
    .offset#{$variant}-2 { margin-left: (2 / 12 * 100%) !important; }
    .offset#{$variant}-3 { margin-left: (3 / 12 * 100%) !important; }
    .offset#{$variant}-4 { margin-left: (4 / 12 * 100%) !important; }
    .offset#{$variant}-5 { margin-left: (5 / 12 * 100%) !important; }
    .offset#{$variant}-6 { margin-left: (6 / 12 * 100%) !important; }
    .offset#{$variant}-7 { margin-left: (7 / 12 * 100%) !important; }
    .offset#{$variant}-8 { margin-left: (8 / 12 * 100%) !important; }
    .offset#{$variant}-9 { margin-left: (9 / 12 * 100%) !important; }
    .offset#{$variant}-10 { margin-left: (10 / 12 * 100%) !important; }
    .offset#{$variant}-11 { margin-left: (11 / 12 * 100%) !important; }
  }
}
