$social-icon-names: (
  fa-stack-overflow,
  fa-github-alt,
  fa-twitter,
  fa-linkedin-square,
  fa-envelope,
  fa-rss,
  fa-building,
  fa-map-marker,
  fa-file
);

$social-icon-name-to-color-name: (
  fa-stack-overflow: stack-overflow,
  fa-github-alt: github,
  fa-twitter: twitter,
  fa-linkedin-square: linkedin,
  fa-envelope: email,
  fa-rss: rss,
  fa-building: employer,
  fa-map-marker: location,
  fa-file: resume
);

$social-icon-containers: (
  employer,
  location,
  resume
);

$social-icon-container-name-to-color-name: (
  employer: employer,
  location: location,
  resume: resume
);

.social-icon {
  @include transition(.4s);
}

@each $name in $social-icon-names {
  .#{$name}:hover {
    @include social_color($social-icon-name-to-color-name, $name);
  }
}

@each $name in $social-icon-containers {
  .#{$name}:hover span {
    @include social_color($social-icon-container-name-to-color-name, $name);
  }
}

/* Social links in footer */
ul.social-links, .credits{ 
  list-style: none;
  text-align: center;
  margin: 0 auto;
}
ul.social-links li {
  display: inline;
  padding: 0.5rem 0.25rem;
  margin-bottom: 0.5rem;
}
ul.social-links a {
  color: $social-color;
  text-decoration: none;
  font-size: $h4-size;
  background-repeat: repeat;
}