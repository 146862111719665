// A rounded corner box containing a label "open" or "closed"
// The default state is gray.
//
// open     - $green background
// reopened - $green background
// closed   - $red background
// merged   - $purple background

// Default 32px
// Small 24px

.state, // TODO: Deprecate
.State {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  padding: 5px 12px;
  font-size: $body-font-size;
  font-weight: $font-weight-semibold;
  // stylelint-disable-next-line primer/typography
  line-height: 20px;
  color: $text-white;
  text-align: center;
  white-space: nowrap;
  // stylelint-disable-next-line primer/colors
  background-color: $gray-500;
  border: $border-width $border-style transparent; // Support Firfox custom colors
  // stylelint-disable-next-line primer/borders
  border-radius: 2em;
}

.State--green {
  background-color: $bg-green;
}

.State--red {
  background-color: $bg-red;
}

.State--purple {
  background-color: $bg-purple;
}

// Small

.State--small {
  // stylelint-disable-next-line primer/spacing
  padding: 0 10px;
  font-size: $font-size-small;
  // stylelint-disable-next-line primer/typography
  line-height: $size-3;

  .octicon {
    width: 1em; // Ensures different icons don't change State indicator width
  }
}
