// Needs refactoring
// Sub nav
.subnav {
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 20px;

  @include clearfix();
}

.subnav-bordered {
  // stylelint-disable-next-line primer/spacing
  padding-bottom: 20px;
  border-bottom: $border-width $border-style $border-gray-light;
}

.subnav-flush {
  margin-bottom: 0;
}

.subnav-item {
  position: relative;
  float: left;
  // stylelint-disable-next-line primer/spacing
  padding: 5px $spacer-3;
  font-weight: $font-weight-semibold;
  // stylelint-disable-next-line primer/typography
  line-height: 20px;
  color: $text-gray-dark;
  border: $border;

  + .subnav-item {
    // stylelint-disable-next-line primer/spacing
    margin-left: -1px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $bg-gray;
  }

  &.selected,
  &[aria-selected=true],
  &[aria-current]:not([aria-current=false]) {
    z-index: 2;
    color: $text-white;
    background-color: $bg-blue;
    // stylelint-disable-next-line primer/borders
    border-color: $blue-600;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.subnav-search {
  position: relative;
  // stylelint-disable-next-line primer/spacing
  margin-left: 12px;
}

.subnav-search-input {
  width: 320px;
  padding-left: $spacer-5;
  color: $text-gray;
}

.subnav-search-input-wide {
  width: 500px;
}

.subnav-search-icon {
  position: absolute;
  top: 9px;
  left: 8px;
  display: block;
  // stylelint-disable-next-line primer/colors
  color: $gray-400;
  text-align: center;
  pointer-events: none;
}

.subnav-search-context {
  .btn {
    // stylelint-disable-next-line primer/colors
    color: $gray-700;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover,
    &:focus,
    &:active,
    &.selected {
      z-index: 2;
    }
  }

  + .subnav-search {
    // stylelint-disable-next-line primer/spacing
    margin-left: -1px;

    .subnav-search-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .select-menu-modal-holder {
    z-index: 30;
  }

  .select-menu-modal {
    width: 220px;
  }

  .select-menu-item-icon {
    color: inherit;
  }
}

.subnav-spacer-right {
  // stylelint-disable-next-line primer/spacing
  padding-right: 12px;
}
