/* Tag list */
.tags {
    padding-left: 0;
    margin: 5px 0px 30px 0px;
    padding: 5px 0px 30px 0px;
    list-style: none;
  }
  .tags li, .tags a{
    float: left;
    height: 24px;
    line-height: 24px;
    position: relative;
    font-size: $h6-size;
    text-shadow: none;
    margin-top: 0;
  }
  .tags a {
    margin-left: 20px;
    padding: 0 15px 0 15px;
    background: #111;
    color: #fffff8;
    text-decoration: none;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  // Draw 'point' of tag label.
  .tags a:before {
    content: "";
    float: left;
    position: absolute;
    top: 0;
    left: -12px;
    width: 0;
    height: 0;
    border-color: transparent #111 transparent transparent;
    border-style: solid;
    border-width: 12px 12px 12px 0;
  }
  // Draw 'dot' in tag label.
  .tags a:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    float: left;
    width: 4px;
    height: 4px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background: #fffff8;
  }
  .tags a:hover {
    background: #555;
  }
  .tags a:hover:before {
    border-color:transparent #555 transparent transparent;
  }