// Fixed-width, centered column for site content.
// Handy container styles that match our breakpoints

// 544px
.container-sm {
  max-width: $width-sm;
  margin-right: auto;
  margin-left: auto;
}

// 768px
.container-md {
  max-width: $container-md;
  margin-right: auto;
  margin-left: auto;
}

// 1004px - this matches the current fixed width: 980px + padding: px-3
.container-lg {
  max-width: $container-lg;
  margin-right: auto;
  margin-left: auto;
}

// 1280px
.container-xl {
  max-width: $container-xl;
  margin-right: auto;
  margin-left: auto;
}
