// Labels

// Provide a wrapper to ensure labels stick together
.labels {
  position: relative;
}

// Default label

.label, // TODO: Deprecte
.Label {
  @include labels-base;
  background-color: transparent !important; // TODO: Remove again
  border-color: $border-color;

  &:hover {
    text-decoration: none;
  }
}

// Large

.Label--large {
  @include labels-large;
}

// Inline

.Label--inline {
  @include labels--inline;
}

// Contrast

.Label--outline, // TODO: Deprecate? It's now the default
.Label--gray {
  color: $text-gray;
  border-color: $border-gray;
}

.Label--gray-darker {
  color: $text-gray-dark;
  // stylelint-disable-next-line primer/borders
  border-color: $gray-500;
}

// Colors

.Label--yellow {
  // stylelint-disable-next-line primer/colors
  color: $yellow-900;
  // stylelint-disable-next-line primer/borders
  border-color: $yellow-800;
}

.Label--orange {
  // stylelint-disable-next-line primer/colors
  color: $orange-800;
  // stylelint-disable-next-line primer/borders
  border-color: $orange-500;
}

.Label--red {
  color: $text-red;
  // stylelint-disable-next-line primer/borders
  border-color: $red-600;
}

.Label--outline-green, // TODO: Deprecate
.Label--green {
  // stylelint-disable-next-line primer/colors
  color: $green-600;
  // stylelint-disable-next-line primer/borders
  border-color: $green-500;
}

.Label--blue {
  color: $text-blue;
  border-color: $border-blue;
}

.Label--purple {
  color: $text-purple;
  // stylelint-disable-next-line primer/borders
  border-color: $purple-400;
}

.Label--pink {
  // stylelint-disable-next-line primer/colors
  color: $pink-600;
  // stylelint-disable-next-line primer/borders
  border-color: $pink-400;
}
