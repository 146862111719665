// stylelint-disable selector-max-type

.details-overlay[open] > summary::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 80;
  display: block;
  cursor: default;
  content: " ";
  background: transparent;
}

.details-overlay-dark[open] > summary::before {
  z-index: 99;
  background: $bg-black-fade;
}

.details-reset {
  // Remove marker added by the display: list-item browser default
  > summary { list-style: none; }
  // Remove marker added by details polyfill
  > summary::before { display: none; }
  // Remove marker added by Chrome
  > summary::-webkit-details-marker { display: none; }
}
