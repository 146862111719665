.img-center {
  display: block;
  margin-bottom: 1em;
  float: center;
}

.img-left {
  display: block;
  margin-right: 1em;
  float: left;
}

.img-right {
  display: block;
  margin-left: 1em;
  float: right;
}
