// Truncate
//
// css-truncate will shorten text with an ellipsis.

.css-truncate {

  // css-truncate-auto will shorten text with an ellipsis when overflowing
  &.css-truncate-overflow,
  .css-truncate-overflow,
  &.css-truncate-target,
  .css-truncate-target {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // css-truncate-target will shorten text with an ellipsis and a max width
  &.css-truncate-target,
  .css-truncate-target {
    display: inline-block;
    max-width: 125px;
    vertical-align: top;
  }

  &.expandable.zeroclipboard-is-hover .css-truncate-target,
  &.expandable.zeroclipboard-is-hover.css-truncate-target,
  &.expandable:hover .css-truncate-target,
  &.expandable:hover.css-truncate-target {
    max-width: 10000px !important;
  }
}
