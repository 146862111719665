// Progress

.Progress {
  display: flex;
  height: 8px;
  overflow: hidden;
  // stylelint-disable-next-line primer/colors
  background-color: $gray-200;
  border-radius: $border-radius;
  outline: 1px solid transparent; // Support Firefox custom colors
}

.Progress--large {
  height: 10px;
}

.Progress--small {
  height: 5px;
}

.Progress-item {
  outline: 2px solid transparent; // Support Firefox custom colors
}

.Progress-item + .Progress-item {
  // stylelint-disable-next-line primer/spacing
  margin-left: 2px;
}
