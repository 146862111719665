// A pop up list of items used to show autocompleted results
.autocomplete-results {
  position: absolute;
  z-index: 99;
  width: 100%;
  max-height: 20em;
  overflow-y: auto;
  // stylelint-disable-next-line primer/typography
  font-size: 13px;
  list-style: none;
  background: $bg-white;
  border: $border;
  border-radius: $border-radius;
  box-shadow: $box-shadow-medium;
}

// One of the items that appears within an autocomplete group
// Bold black text on white background

.autocomplete-item {
  display: block;
  width: 100%;
  padding: $spacer-1 $spacer-2;
  overflow: hidden;
  font-weight: $font-weight-bold;
  color: $text-gray-dark;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: $bg-white;
  border: 0;

  &:hover,
  &.selected,
  &[aria-selected=true],
  &.navigation-focus {
    color: $text-white;
    text-decoration: none;
    background-color: $bg-blue;

    // Inherit color on all child elements to ensure enough contrast
    * {
      color: inherit !important;
    }
  }
}
