/* Hawk logo in page header */
.title-logo {
  width: 75px;
  height: auto;
  display: inline;
  vertical-align: center;
}

/* Date/Author information for the page in footer */
.credits {
    padding: 1rem 0rem;
    font-size: 0.95rem;
  }

/* Make "Return home" link small and aligned */
p.return-home {
    font-size: 1.2rem;
    width: 50%;
    float: left;
  }
  
  /* HR between content and footer */
  .footer-hr {
    border: 0;
    width: 100%;
    height: 1px;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    background-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
    background-image:    -moz-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
    background-image:     -ms-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
    background-image:      -o-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,0.75), rgba(0,0,0,0));
  }