.UnderlineNav {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  // stylelint-disable-next-line primer/box-shadow
  box-shadow: inset 0 -1px 0 $border-color;
  justify-content: space-between;
}

.UnderlineNav-body {
  display: flex;
}

.UnderlineNav-item {
  padding: $spacer-2 $spacer-3;
  font-size: $body-font-size;
  // stylelint-disable-next-line primer/typography
  line-height: 30px;
  color: $text-black;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  // stylelint-disable-next-line primer/borders
  border-bottom: 2px $border-style rgba($border-gray-dark, 0);
  transition: border-bottom-color 0.36s ease-in;

  &:hover,
  &:focus {
    text-decoration: none;
    border-bottom-color: $border-gray-dark;
    outline: 1px dotted transparent; // Support Firfox custom colors
    outline-offset: -1px;
    transition-timing-function: ease-out;
    transition-duration: 0.12s;
  }

  &.selected,
  &[role=tab][aria-selected=true],
  &[aria-current]:not([aria-current=false]) {
    font-weight: $font-weight-bold;
    // stylelint-disable-next-line primer/borders
    border-bottom-color: #f9826c; // custom coral
    outline: 1px dotted transparent; // Support Firfox custom colors
    outline-offset: -1px;

    .UnderlineNav-octicon {
      color: $text-gray;
    }
  }
}

.UnderlineNav--right {
  justify-content: flex-end;

  .UnderlineNav-actions {
    flex: 1 1 auto;
  }
}

.UnderlineNav-actions {
  align-self: center;
}

.UnderlineNav--full {
  display: block;
}

.UnderlineNav-octicon {
  margin-right: $spacer-1;
  // stylelint-disable-next-line primer/colors
  color: $gray-400;
}

.UnderlineNav .Counter {
  margin-left: $spacer-1;
}

.UnderlineNav-container {
  display: flex;
  justify-content: space-between;
}
