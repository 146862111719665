.avatar {
  display: inline-block;
  overflow: hidden; // Ensure page layout in Firefox should images fail to load
  line-height: $lh-condensed-ultra;
  vertical-align: middle;
  border-radius: $border-radius;
}

.avatar-link {
  float: left;
  line-height: $lh-condensed-ultra;
}

// User for example on /stars and /user for grids of avatars
.avatar-group-item {
  display: inline-block;
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 3px;
}

// Border radius

.avatar-1,
.avatar-2,
.avatar-small {
  // stylelint-disable-next-line primer/borders
  border-radius: $border-radius-1;
}

// Sizes

@mixin avatar-size( $size ) {
  width: $size;
  height: $size;
}

.avatar-1 { @include avatar-size( $size-1 ); }
.avatar-2 { @include avatar-size( $size-2 ); }
.avatar-3 { @include avatar-size( $size-3 ); }
.avatar-4 { @include avatar-size( $size-4 ); }
.avatar-5 { @include avatar-size( $size-5 ); }
.avatar-6 { @include avatar-size( $size-6 ); }
.avatar-7 { @include avatar-size( $size-7 ); }
.avatar-8 { @include avatar-size( $size-8 ); }
