// Box shadow utilities

// Box shadows

.box-shadow {
  box-shadow: $box-shadow !important;
}

.box-shadow-medium {
  box-shadow: $box-shadow-medium !important;
}

.box-shadow-large {
  box-shadow: $box-shadow-large !important;
}

.box-shadow-extra-large {
  box-shadow: $box-shadow-extra-large !important;
}

// Turn off box shadow

.box-shadow-none {
  box-shadow: none !important;
}
