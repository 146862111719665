// Counters are rounded-corner badges for numbers
.Counter {
  display: inline-block;
  min-width: $size-2; // makes sure it's a circle with just one digit
  // stylelint-disable-next-line primer/spacing
  padding: 0 6px;
  font-size: $font-size-small;
  font-weight: $font-weight-semibold;
  // stylelint-disable-next-line primer/typography
  line-height: $size-2 - 2px; // remove borders
  color: $text-gray-dark;
  text-align: center;
  // stylelint-disable-next-line primer/colors
  background-color: rgba($gray-300, 0.5);
  border: $border-width $border-style transparent; // Support Firfox custom colors
  // stylelint-disable-next-line primer/borders
  border-radius: 2em;

  &:empty {
    display: none;
  }

  .octicon {
    vertical-align: text-top;
    opacity: 0.8;
  }
}

.Counter--gray-light {
  color: $text-gray-light;
}

.Counter--gray {
  color: $text-white;
  // stylelint-disable-next-line primer/colors
  background-color: $gray-500;
}
