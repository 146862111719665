// Side Nav
//
// A vertical list of navigational links, typically used on the left side of a page.

.SideNav {
  background-color: $bg-gray-light;
}

.SideNav-item {
  position: relative;
  display: block;
  width: 100%;
  // stylelint-disable-next-line primer/spacing
  padding: 12px $spacer-3;
  color: $text-black;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-top: $border-width $border-style $border-gray-light;

  &:first-child {
    border-top: 0;
  }

  &:last-child {
    // makes sure there is a "bottom border" in case the list is not long enough
    // stylelint-disable-next-line primer/box-shadow
    box-shadow: 0 $border-width 0 $border-color;
  }

  // Bar on the left
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 2px;
    pointer-events: none;
    content: "";
  }
}

// States

.SideNav-item:hover,
.SideNav-item:focus {
  text-decoration: none;
  // stylelint-disable-next-line primer/colors
  background-color: $gray-100;
  outline: none;
}

.SideNav-item:active {
  background-color: $bg-gray-light;
}

.SideNav-item[aria-current]:not([aria-current=false]),
.SideNav-item[aria-selected="true"] {
  background-color: $bg-white;

  // Bar on the left
  &::before {
    // stylelint-disable-next-line primer/colors
    background-color: #f9826c; // custom coral
  }
}

// Icon
//
// Makes sure multiple icons are vertically aligned

.SideNav-icon {
  width: 16px;
  margin-right: $spacer-2;
  color: $text-gray-light;
}

// Sub Nav
//
// A more lightweight version, suited as a sub nav

.SideNav-subItem {
  position: relative;
  display: block;
  width: 100%;
  padding: $spacer-1 0;
  color: $text-blue;
  text-align: left;
  background-color: transparent;
  border: 0;
}

.SideNav-subItem:hover,
.SideNav-subItem:focus {
  color: $text-gray-dark;
  text-decoration: none;
  outline: none;
}

.SideNav-subItem[aria-current]:not([aria-current=false]),
.SideNav-subItem[aria-selected="true"] {
  font-weight: $font-weight-semibold;
  color: $text-gray-dark;
}
