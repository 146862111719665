// Override typography variables from primer.

// Heading sizes - mobile
// h4-h6 remain the same size on both mobile & desktop
$h00-size-mobile: 42px !default;
$h0-size-mobile: 34px !default;
$h1-size-mobile: 28px !default;
$h2-size-mobile: 24px !default;
$h3-size-mobile: 20px !default;

// Heading sizes - desktop
$h00-size: 50px !default;
$h0-size: 42px !default;
$h1-size: 34px !default;
$h2-size: 26px !default;
$h3-size: 22px !default;
$h4-size: 18px !default;
$h5-size: 16px !default;
$h6-size: 14px !default;

$font-size-small: 14px !default;

// The base body size
$body-font-size: 16px !default;