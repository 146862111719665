// stylelint-disable selector-max-type, no-duplicate-selectors

// Default flash
.flash {
  position: relative;
  // stylelint-disable-next-line primer/spacing
  padding: 20px $spacer-3;
  color: $text-gray-dark;
  border-style: $border-style;
  border-width: $border-width;
  border-radius: $border-radius;

  p:last-child {
    margin-bottom: 0;
  }

  .octicon {
    // stylelint-disable-next-line primer/spacing
    margin-right: 12px;
  }
}

// Contain the flash messages
.flash-messages {
  margin-bottom: $spacer-4;
}

// Close button
.flash-close {
  float: right;
  padding: $spacer-3;
  margin: -$spacer-3;
  text-align: center;
  cursor: pointer;

  // Undo `<button>` styles
  background: none;
  border: 0;
  appearance: none;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }

  .octicon {
    margin-right: 0;
  }
}

// Action button
.flash-action {
  float: right;
  // stylelint-disable-next-line primer/spacing
  margin-top: -3px;
  margin-left: $spacer-4;
  background-clip: padding-box;
}

//
// Color variations
//

.flash {
  // stylelint-disable-next-line primer/colors
  background-color: $blue-100;
  // stylelint-disable-next-line primer/borders
  border-color: rgba($blue-700, 0.2);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: rgba($blue-700, 0.6);
  }
}

.flash-warn {
  // stylelint-disable-next-line primer/colors
  background-color: $yellow-100;
  // stylelint-disable-next-line primer/borders
  border-color: rgba($yellow-800, 0.2);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: rgba($yellow-800, 1);
  }
}

.flash-error {
  // stylelint-disable-next-line primer/colors
  background-color: #ffe3e6; // custom red
  // stylelint-disable-next-line primer/borders
  border-color: rgba($red-800, 0.2);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: rgba($red-800, 0.6);
  }
}

.flash-success {
  background-color: $bg-green-light;
  // stylelint-disable-next-line primer/borders
  border-color: rgba($green-700, 0.2);

  .octicon {
    // stylelint-disable-next-line primer/colors
    color: rgba($green-700, 0.8);
  }
}

//
// Layout variations
//

.flash-full {
  // stylelint-disable-next-line primer/spacing
  margin-top: -1px;
  border-width: $border-width 0;
  border-radius: 0;
}

// A banner rendered at the top of the page.
.flash-banner {
  position: fixed;
  top: 0;
  z-index: 90;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

// FIXME deprecate this
.warning {
  padding: $em-spacer-5;
  // stylelint-disable-next-line primer/spacing
  margin-bottom: 0.8em;
  font-weight: $font-weight-bold;
  // stylelint-disable-next-line primer/colors
  background-color: $yellow-100;
}
