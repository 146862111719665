// Global
$background-color: #fffff8 !default;
$text-color: #111111 !default;
$danger-color: red !default;
$contrast-color: #a00000 !default;
$border-color: #333333 !default;

// Social
$social-color: $text-color !default;
$social-stack-overflow-color: #FF9900 !default;
$social-github-color: #151013 !default;
$social-twitter-color: #00aced !default;
$social-linkedin-color: #0073a4 !default;
$social-email-color: #808080 !default;
$social-rss-color: #EE802F !default;
$social-employer-color: #2E1046 !default;
$social-location-color: #3cba54 !default;
$social-resume-color: #808080 !default;

$social-colors: (
  stack-overflow: $social-stack-overflow-color,
  github: $social-github-color,
  twitter: $social-twitter-color,
  linkedin: $social-linkedin-color,
  email: $social-email-color,
  rss: $social-rss-color,
  employer: $social-employer-color,
  location: $social-location-color,
  resume: $social-resume-color
);
