// Needs refactoring
// stylelint-disable selector-no-qualifying-type, selector-max-compound-selectors
// stylelint-disable selector-max-type
// Base form controls
//
// Overrides for common inputs for easier styling.

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

label {
  font-weight: $font-weight-bold;
}

.form-control,
.form-select {
  // stylelint-disable-next-line primer/spacing
  padding: 5px 12px;
  font-size: $body-font-size;
  // stylelint-disable-next-line primer/typography
  line-height: 20px;
  color: $text-gray-dark;
  vertical-align: middle;
  background-color: $bg-white;
  background-repeat: no-repeat; // Repeat and position set for form states (success, error, etc)
  background-position: right 8px center; // For form validation. This keeps images 8px from right and centered vertically.
  border: $border-width $border-style $border-color;
  border-radius: $border-radius;
  outline: none;
  box-shadow: $box-shadow-inset;

  &.focus,
  &:focus {
    border-color: $border-blue;
    outline: none;
    box-shadow: $box-shadow-focus;
  }

  &[disabled] {
    // stylelint-disable-next-line primer/colors
    color: $gray-400;
    // stylelint-disable-next-line primer/colors
    background-color: #f3f4f6; // custom gray
  }

  // Ensures inputs don't zoom on mobile iPhone but are body-font size on iPad
  @supports (-webkit-touch-callout: none) {
    font-size: $h4-size;
    @include breakpoint(md) {
      font-size: $body-font-size;
    }
  }
}

// Textarea
textarea.form-control {
  padding-top: $spacer-2;
  padding-bottom: $spacer-2;
  line-height: $lh-default;
}

// Inputs with contrast for easy light gray backgrounds against white.
.input-contrast {
  background-color: $bg-gray-light;

  &:focus { background-color: $bg-white; }
}

// Inputs to be used against dark backgrounds.
.input-dark {
  color: $text-white;
  // stylelint-disable-next-line primer/colors
  background-color: $white-fade-15;
  border-color: transparent;
  box-shadow: none;

  &::placeholder {
    color: inherit;
    opacity: 0.6; // inceases contrast ratio to 4.52
  }

  &.focus,
  &:focus {
    // stylelint-disable-next-line primer/borders
    border-color: $black-fade-30;
    // stylelint-disable-next-line primer/box-shadow
    box-shadow: 0 0 0 0.2em rgba($blue-300, 0.4);
  }
}

// Custom styling for HTML5 validation bubbles (WebKit only)
::placeholder {
  color: $text-gray-light;
  opacity: 1; // override opacity in normalize.css
}

// Mini inputs, to match .minibutton
.input-sm {
  // stylelint-disable-next-line primer/spacing
  padding-top: 3px;
  // stylelint-disable-next-line primer/spacing
  padding-bottom: 3px;
  font-size: $font-size-small;
  // stylelint-disable-next-line primer/typography
  line-height: 20px;
}

// Large inputs
.input-lg {
  font-size: $h4-size;
}

// Full-width inputs
.input-block {
  display: block;
  width: 100%;
}

// Inputs with monospace text
.input-monospace {
  font-family: $mono-font;
}

// Hide the icon that tries to autofill contact info in webkit
.input-hide-webkit-autofill {
  &::-webkit-contacts-auto-fill-button {
    position: absolute;
    right: 0;
    display: none !important;
    pointer-events: none;
    visibility: hidden;
  }
}

// Checkboxes and Radiobuttons
//
// For checkboxes and radio button selections.
.form-checkbox {
  // stylelint-disable-next-line primer/spacing
  padding-left: 20px;
  // stylelint-disable-next-line primer/spacing
  margin: 15px 0;
  vertical-align: middle;

  label {
    em.highlight {
      position: relative;
      left: -$spacer-1;
      // stylelint-disable-next-line primer/spacing
      padding: 2px $spacer-1;
      font-style: normal;
      // stylelint-disable-next-line primer/colors
      background: $yellow-100;
      border-radius: $border-radius;
    }
  }

  input[type=checkbox],
  input[type=radio] {
    float: left;
    // stylelint-disable-next-line primer/spacing
    margin: 5px 0 0 -20px;
    vertical-align: middle;
  }

  .note {
    display: block;
    margin: 0;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    color: $text-gray;
  }
}

.form-checkbox-details {
  display: none;
}

.form-checkbox-details-trigger {
  &:checked {
    // child of sibling or sibling
    ~ * .form-checkbox-details,
    ~ .form-checkbox-details { // eslint-disable selector-combinator-space-before
      display: block;
    }
  }
}

// Field groups
//
// Wrap field groups in `<div.hfields>` to lay them out horizontally - great for
// the top of pages with autosave.
.hfields {
  // stylelint-disable-next-line primer/spacing
  margin: 15px 0;
  @include clearfix;

  .form-group {
    float: left;
    // stylelint-disable-next-line primer/spacing
    margin: 0 30px 0 0;

    dt, // TODO: Deprecate
    .form-group-header {
      label {
        display: inline-block;
        // stylelint-disable-next-line primer/spacing
        margin: 5px 0 0;
        color: $text-gray;
      }

      img {
        position: relative;
        top: -2px;
      }
    }
  }

  .btn {
    float: left;
    // stylelint-disable-next-line primer/spacing
    margin: 28px 25px 0 -20px;
  }

  // stylelint-disable-next-line primer/spacing
  .form-select { margin-top: 5px; }
}

// Hide the up/down buttons in <input type="number"> in the login form, the
// input is used for two-factor auth codes, type="number" makes it more usable
// on phones
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

// Input groups

.form-actions {
  @include clearfix;

  .btn {
    float: right;

    + .btn {
      // stylelint-disable-next-line primer/spacing
      margin-right: 5px;
    }
  }
}

.form-warning {
  padding: $spacer-2 10px;
  // stylelint-disable-next-line primer/spacing
  margin: 10px 0;
  font-size: $h5-size;
  // stylelint-disable-next-line primer/colors
  color: $yellow-900;
  // stylelint-disable-next-line primer/colors
  background: $yellow-100;
  border: $border-width $border-style $border-yellow;
  border-radius: $border-radius;

  p {
    margin: 0;
    line-height: $lh-default;
  }

  a { font-weight: $font-weight-bold; }
}
