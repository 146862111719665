// Needs refactoring
// stylelint-disable selector-max-type
.pagination {
  a,
  span,
  em {
    display: inline-block;
    min-width: 32px;
    // stylelint-disable-next-line primer/spacing
    padding: 5px 10px;
    font-style: normal;
    // stylelint-disable-next-line primer/typography
    line-height: 20px;
    color: $text-gray-dark;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: $border-width $border-style transparent;
    border-radius: $border-radius;
    transition: border-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);

    &:hover,
    &:focus {
      text-decoration: none;
      border-color: $border-color;
      outline: 0;
      transition-duration: 0.1s;
    }

    &:active {
      border-color: $border-gray-light;
      transition: none;
    }
  }

  .previous_page,
  .next_page {
    color: $text-blue;
  }

  .current,
  .current:hover,
  [aria-current]:not([aria-current=false]) {
    color: $text-white;
    background-color: $bg-blue;
    border-color: transparent;
  }

  .gap,
  .disabled,
  [aria-disabled=true],
  .gap:hover,
  .disabled:hover,
  [aria-disabled=true]:hover {
    color: $text-gray-light;
    cursor: default;
    border-color: transparent;
  }

  // chevron icons using clip-path
  @supports (clip-path: polygon(50% 0, 100% 50%, 50% 100%)) {
    .previous_page::before,
    .next_page::after {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: text-bottom;
      content: "";
      // stylelint-disable-next-line primer/colors
      background-color: currentColor;
    }

    // chevron-left
    .previous_page::before {
      margin-right: $spacer-1;
      clip-path: polygon(9.8px 12.8px, 8.7px 12.8px, 4.5px 8.5px, 4.5px 7.5px, 8.7px 3.2px, 9.8px 4.3px, 6.1px 8px, 9.8px 11.7px, 9.8px 12.8px);
    }

    // chevron-right
    .next_page::after {
      margin-left: $spacer-1;
      clip-path: polygon(6.2px 3.2px, 7.3px 3.2px, 11.5px 7.5px, 11.5px 8.5px, 7.3px 12.8px, 6.2px 11.7px, 9.9px 8px, 6.2px 4.3px, 6.2px 3.2px);
    }
  }
}

// Unified centered pagination across the site
.paginate-container {
  margin-top: $spacer-3;
  margin-bottom: $spacer-3;
  text-align: center;

  .pagination {
    display: inline-block;
  }
}
