// Side menu
//
// A menu on the side of a page, defaults to left side. e.g. github.com/about

.menu {
  margin-bottom: $spacer-3;
  list-style: none;
  background-color: $bg-white;
  border: $border;
  border-radius: $border-radius;
}

.menu-item {
  position: relative;
  display: block;
  padding: $spacer-2 $spacer-3;
  color: $text-black;
  border-bottom: $border-width $border-style $border-gray-light;

  &:first-child {
    border-top: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    &::before { border-top-left-radius: $border-radius; }
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    &::before { border-bottom-left-radius: $border-radius; }
  }

  &:focus,
  &:hover {
    text-decoration: none;
    // stylelint-disable-next-line primer/colors
    background-color: $gray-100;
    outline: none;
  }

  &:active {
    background-color: $bg-gray-light;
  }

  &.selected,
  &[aria-selected=true],
  &[aria-current]:not([aria-current=false]) {
    cursor: default;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 2px;
      content: "";
      // stylelint-disable-next-line primer/colors
      background-color: #f9826c; // custom coral
    }
  }

  .octicon {
    width: 16px;
    margin-right: $spacer-2;
    // stylelint-disable-next-line primer/colors
    color: $gray-400;
    text-align: center;
  }

  .Counter {
    float: right;
    margin-left: $spacer-1;
  }

  .menu-warning {
    float: right;
    // stylelint-disable-next-line primer/colors
    color: $red-900;
  }

  .avatar {
    float: left;
    margin-right: $spacer-1;
  }

  &.alert {
    .Counter {
      color: $text-red;
    }
  }
}

.menu-heading {
  display: block;
  padding: $spacer-2 $spacer-3;
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
  font-weight: $font-weight-bold;
  color: $text-black;
  border-bottom: $border-width $border-style $border-gray-light;

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}
