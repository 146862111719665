// stylelint-disable selector-max-compound-selectors
// stylelint-disable selector-max-type

// Form groups
//
// Usage:
//
// <div class="form-group">
//   <div class="form-group-header"> containing the label
//   <div class="form-group-body"> containing the form elements
// </div>

.form-group {
  // stylelint-disable-next-line primer/spacing
  margin: 15px 0;

  // Text fields
  .form-control {
    width: 440px;
    max-width: 100%;
    // stylelint-disable-next-line primer/spacing
    margin-right: 5px;
    background-color: $bg-gray-light;

    &:focus {
      background-color: $bg-white;
    }

    &.shorter { width: 130px; }

    &.short { width: 250px; }

    &.long { width: 100%; }
  }

  // Textarea
  // stylelint-disable selector-no-qualifying-type
  textarea.form-control {
    width: 100%;
    height: 200px;
    min-height: 200px;

    &.short {
      height: 50px;
      min-height: 50px;
    }
  }
  // stylelint-enable selector-no-qualifying-type

  // The Label
  dt, // TODO: Deprecate
  .form-group-header {
    // stylelint-disable-next-line primer/spacing
    margin: 0 0 6px;
  }

  label {
    position: relative;
  }

  &.flattened dt, // TODO: Deprecate
  &.flattened .form-group-header {
    float: left;
    margin: 0;
    // stylelint-disable-next-line primer/typography
    line-height: 32px;
  }

  &.flattened dd, // TODO: Deprecate
  &.flattened .form-group-body {
    // stylelint-disable-next-line primer/typography
    line-height: 32px;
  }

  //
  // Form Elements
  //
  // stylelint-disable selector-no-qualifying-type
  dd, // TODO: Deprecate
  .form-group-body {
    h4 {
      margin: $spacer-1 0 0;

      &.is-error { color: $text-red; }

      &.is-success { color: $text-green; }

      + .note {
        margin-top: 0;
      }
    }
  }
  // stylelint-enable selector-no-qualifying-type

  //
  // Variants
  //

  &.required {
    dt label::after, // TODO: Deprecate
    .form-group-header label::after {
      // stylelint-disable-next-line primer/spacing
      padding-left: 5px;
      color: $text-red;
      content: "*";
    }
  }

  // Form AJAX states
  //
  // Form fields that need feedback for AJAX loading, success
  // states and errored states.
  .success,
  .error,
  .indicator {
    display: none;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
  }

  &.loading {
    opacity: 0.5;

    .indicator {
      display: inline;
    }

    .spinner {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.successful {
    .success {
      display: inline;
      color: $text-green;
    }
  }

  // Form validation
  //
  // Our inline errors

  &.successed,
  &.warn,
  &.errored {
    .success,
    .warning,
    .error {
      position: absolute;
      z-index: 10;
      display: block; // Show up in errored/warn state
      max-width: 450px; // Keep our long errors readable
      padding: $spacer-1 $spacer-2;
      margin: $spacer-2 0 0;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
      border-style: $border-style;
      border-width: $border-width;
      border-radius: $border-radius;

      &::after,
      &::before {
        position: absolute;
        bottom: 100%;
        left: 10px;
        z-index: 15;
        width: 0;
        height: 0;
        pointer-events: none;
        content: " ";
        // stylelint-disable-next-line primer/borders
        border: $border-style transparent;
      }

      &::after {
        // stylelint-disable-next-line primer/borders
        border-width: 5px;
      }

      &::before {
        // stylelint-disable-next-line primer/spacing
        margin-left: -1px;
        // stylelint-disable-next-line primer/borders
        border-width: 6px;
      }
    }
  }

  &.successed {
    .success {
      // stylelint-disable-next-line primer/colors
      color: $green-900;
      // stylelint-disable-next-line primer/colors
      background-color: $green-100;
      border-color: $border-green;

      &::after {
        // stylelint-disable-next-line primer/borders
        border-bottom-color: $green-100;
      }

      &::before {
        border-bottom-color: $border-green;
      }
    }
  }

  &.warn {
    .form-control {
      // stylelint-disable-next-line primer/borders
      border-color: $yellow-600;
    }

    .warning {
      // stylelint-disable-next-line primer/colors
      background-color: $yellow-200;
      border-color: $border-yellow;

      &::after {
        // stylelint-disable-next-line primer/borders
        border-bottom-color: $yellow-200;
      }

      &::before {
        border-bottom-color: $border-yellow;
      }
    }
  }

  &.errored {
    .form-control {
      // stylelint-disable-next-line primer/borders
      border-color: $red-600;
    }

    label {
      color: $text-red;
    }

    .error {
      background-color: $bg-red-light;
      border-color: $border-red-light;

      &::after {
        // stylelint-disable-next-line primer/borders
        border-bottom-color: $bg-red-light;
      }

      &::before {
        border-bottom-color: $border-red-light;
      }
    }
  }
}

.note {
  min-height: 17px;
  margin: $spacer-1 0 2px;
  font-size: $font-size-small;
  color: $text-gray;

  .spinner {
    // stylelint-disable-next-line primer/spacing
    margin-right: 3px;
    vertical-align: middle;
  }
}
